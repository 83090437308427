import '@babel/polyfill';
import {
    arrayFilter,
    arrayFind,
    arrayFindIndex,
    arrayFlat,
    arrayUnique,
    customEvent,
    elementMatches,
    elementRemove,
    objectFromEntries,
    objectValues,
    promise,
    stringIncludes,
    wheelEvent
} from '@mbusa/now-ui-utils.oneweb-polyfills';
import 'intersection-observer';
import 'number-to-locale-string';
import 'web-animations-js';
import 'whatwg-fetch';
import './scrollIntoViewIfNeeded';

export default () => {
    customEvent();
    arrayFilter();
    arrayFind();
    arrayFindIndex();
    arrayUnique();
    arrayFlat();
    elementMatches();
    elementRemove();
    objectFromEntries();
    objectValues();
    promise();
    stringIncludes();
    wheelEvent();
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d
