// Utils dependencies
import importPolyfills from 'utils/polyfills';
import DependencyLoader from 'utils/DependencyLoader';

/**
 * The top-level controller for the MB JS platform page.
 * This app for loading other platform modules and plugin apps.
 */
export default class App {
    /**
     * @constructor
     * @description Initialize all global JS components and call loaders
     * to load the library, platform, apps and plugin dependencies
     * @returns {void}
     */
    constructor() {
        importPolyfills();

        this.dependencyLoader = new DependencyLoader();

        // this load order matters so that each asset type
        // has its dependencies on load
        this.dependencyLoader.loadLibraries(window.mbVans.libraries)
            .then(this.dependencyLoader.loadPlatforms.bind(this.dependencyLoader, window.mbVans.platforms))
            .then(this.dependencyLoader.loadApps.bind(this.dependencyLoader, window.mbVans.apps))
            .then(this.dependencyLoader.loadPlugins.bind(this.dependencyLoader, window.mbVans.plugins));
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d
